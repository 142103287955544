import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import BootstrapTable from 'react-bootstrap-table-next';
import BootstrapTable from 'fad-react-17-bootstrap-table-next';

import { Modal } from 'react-bootstrap';

import campaignColumns from './config/campaignColumns';
import campaignsColumnsWrapper from './config/campaignsColumnsWrapper';
import { Link } from 'react-router-dom';
import formatCurrency from 'utils/formatCurrency';
import formatNumber from 'utils/formatNumber';
import userSession from 'utils/userSession';

export class PlanDetailsModal extends Component {
    static propTypes = {
        show: PropTypes.bool,
        onClose: PropTypes.func,
        planDetails: PropTypes.object
    }

    generateTableData = (mailingPlan) => {
        if(!mailingPlan || !mailingPlan?.campaigns) return [];
    
        return mailingPlan.campaigns?.map(campaign => {
            return campaignsColumnsWrapper(campaign);
        });
    }

    render() {
        const {
            show,
            onClose,
            planDetails
        } = this.props;
         //Code to show column Expiration Date if brand is msq 
         let newCampaignColumns= campaignColumns;
         if(! userSession.isMSQUser()){
            newCampaignColumns = campaignColumns.filter(object => {
                 return object.dataField != "expiration_date" && object.dataField != "desired_in_home_date" && object.dataField != "budget_intention" && object.dataField != "piece_size";
             });
         }
        return (
            <Modal show={show} onHide={() => onClose()} className='mailing-plan-details-modal'>
                <Modal.Header>
                    <i onClick={() => onClose()} className='far fa-times-circle'></i>
                    <div className='modal-title'>
                        Plan details
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='pb-4 mb-4 border-bottom w-100'>
                        <div className='d-flex column-gap-1 row-gap-1 flex-wrap justify-content-around'>
                            <div className='d-flex flex-column'>
                                <small>Request ID</small>
                                <Link
                                    className='mailing-plan-link'
                                    to={`/mailing-plan/${planDetails.franchiseId}/${planDetails.id}/${planDetails.year}`}
                                >
                                    {planDetails.id}
                                </Link>
                            </div>
                            <div className='d-flex flex-column'>
                                <small>Franchise</small>
                                <div className='font-family-bold'>{planDetails.franchise}</div>
                            </div>
                            <div className='d-flex flex-column'>
                                <small>Request Status</small>
                                <div className='font-family-bold'>{planDetails.status}</div>
                            </div>
                            <div className='d-flex flex-column'>
                                <small>Submitted Date</small>
                                <div className='font-family-bold'>{planDetails.submittedDate}</div>
                            </div>
                            <div className='d-flex flex-column'>
                                <small>Modified Date</small>
                                <div className='font-family-bold'>{planDetails.modifiedDate}</div>
                            </div>
                            <div className='d-flex flex-column'>
                                <small>Total Investment</small>
                                <div className='font-family-bold'>${formatCurrency(planDetails.investment)}</div>
                            </div>
                            <div className='d-flex flex-column'>
                                <small>Total Addresses</small>
                                <div className='font-family-bold'>{formatNumber(planDetails.totalAddresses)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='mailing-plan-details-campaigns-table'>
                        <BootstrapTable
                            keyField='id'
                            data={this.generateTableData(planDetails)}
                            columns={newCampaignColumns}
                            bootstrap4={true}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default PlanDetailsModal;
